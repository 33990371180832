@use 'animations/animations';

:root {
	--background-size: 860px;
	--border-radius: 20px;
	--text: #fefefe;

	--black: #031812;
	--brown: #4E4838;
	--green: #0A4B39;
	--blue: #7B9F9F;

	--background: #0c2d48;
	--accent: #B1D4E0;
	--secondary: #2E8BC0;
	--primary: #145DA0;

	--animation-length: 210s;
}

.app {
	text-align: center;
	height: 100vh;
	background-size: var(--background-size);
	overflow: hidden;

	@media (prefers-reduced-motion: no-preference) {
		animation: background-scroll infinite var(--animation-length) linear;
	}

	&__inner {
		display: flex;
		justify-content: center;
		flex-direction: column;
		height: 100%;
		border: 1rem solid transparent;
		background-color: #0c2d481a;
	}

	&__content {
		background-color: var(--background);
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		max-height: 100%;
		padding: 1.5rem 2rem;
		gap: clamp(1.5rem, 3vw, 3rem);
		color: var(--text);
		border-radius: var(--border-radius);
		border: 6px solid var(--primary);
		box-shadow: 0px 4px 10px 2px #0c2d4880;
		min-width: 960px;
		max-width: 1200px;
		margin: 0 auto;

		&--party-time {
			@media (prefers-reduced-motion: no-preference) {
				animation: party-time infinite 2000ms linear,
					on-beat alternate infinite 225ms ease-in;
			}

			.app__action {
				animation: bop infinite 900ms ease-in;

				&--party-time {
					filter: invert(100%);

					@media(hover:hover) {
						&:hover {
							animation: party-time-icon infinite 2000ms linear,
								bop infinite 900ms ease-in;
							transform: translateY(0);
							opacity: 0.5;
						}
					}
				}

				&--dog-switcher {
					@media(hover:hover) {
						&:hover {
							animation: bop infinite 900ms ease-in;
							transform: translateY(0);
							opacity: 0.5;
						}
					}
				}
			}

			.puppy__picture {
				animation: bop infinite 900ms ease-in;
			}

			.puppy,
			.app__select {
				animation: bop reverse infinite 900ms ease-in;
			}
		}
	}

	&__actions-bar {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	&__action {
		height: 4rem;
		width: auto;
		transition: all 200ms ease;
		cursor: pointer;

		@media(hover:hover) {
			&:hover {
				transform: translateY(-4px);
			}
		}

		&--party-time {
			animation: party-time-icon infinite 3000ms linear;
		}

		&--dog-switcher {
			path {
				fill: var(--accent);
			}
		}
	}

	&__select {
		appearance: none;
		width: 12rem;
		border-radius: 4px;
		font-size: 1.25rem;
		padding: 0.5em 3.5em 0.5em 0.75em;
		cursor: pointer;
		background-image:
			linear-gradient(45deg, transparent 50%, gray 50%),
			linear-gradient(135deg, gray 50%, transparent 50%),
			linear-gradient(to right, #ccc, #ccc);
		background-position:
			calc(100% - 20px) calc(1em),
			calc(100% - 15px) calc(1em),
			calc(100% - 2em) 0.35em;
		background-size:
			5px 5px,
			5px 5px,
			1px 1.5em;
		background-repeat: no-repeat;
	}
}

.puppy {
	position: relative;
	z-index: 2;
	margin-bottom: 3rem;

	&__picture {
		position: relative;
		z-index: 2;
		height: clamp(200px, 50vh, 600px);
		width: clamp(200px, 50vh, 600px);
		pointer-events: none;
		border-radius: var(--border-radius);
		border: 4px solid var(--accent);
		box-shadow: 0px 2px 8px 4px #0c2d4880;
		object-fit: cover;
	}

	&__drawer {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin: 0 auto -4.5rem;
		padding: 1.5rem 1rem 1.25rem;
		background-color: var(--secondary);
		border: 4px solid var(--accent);
		border-radius: 0 0 20px 20px;
		transform: translateY(-103%);
		z-index: 1;
		box-shadow: 0px 2px 8px 4px #0c2d4880;
		transition: all 350ms ease;

		select {
			pointer-events: none;
		}

		label {
			width: 12rem;
			text-align: left;
			margin-bottom: 6px;
			text-indent: 4px;
		}

		&--open {
			transform: translateY(-1rem);
			margin-bottom: 0;

			select {
				pointer-events: all;
			}

			.appa-wrap__toggle span {
				transform: rotate(180deg);
				transform-origin: center;
				line-height: 3rem;
			}
		}
	}

	&__toggle {
		position: absolute;
		left: calc(50% - 2rem);
		bottom: -2.5rem;
		height: 2.5rem;
		width: 4rem;
		background-color: var(--secondary);
		border: 4px solid var(--accent);
		border-top: none;
		border-radius: 0 0 10px 10px;
		cursor: pointer;

		span {
			display: inline-block;
			height: 2.5rem;
			line-height: 2.5rem;
			transition: all 350ms ease;
		}
	}

	&__photos-button {
		background: transparent;
		padding: 0;
		margin: 0;
		border: none;
		color: inherit;
		cursor: pointer;

		@media(hover:hover) {
			&:hover {
				.age span {
					transform: translateY(-4px);
				}
			}
		}
	}
}


.age {
	font-size: clamp(1rem, 6vh, 2.5rem);
	margin: 0 0 2rem 0;

	span {
		display: inline-block;
		color: var(--accent);
		text-shadow: 1px 1px 0px var(--primary);
		transition: all 200ms ease;
	}
}

@media(max-width: 992px) {
	.app {
		&__content {
			min-width: 100%;
		}
	}
}


@media(max-width: 760px) {
	.app {
		&__content {
			padding: 1rem;
		}

		&__party-time {

			img {
				height: clamp(2rem, 7vh, 4rem);
				width: clamp(2rem, 7vh, 4rem);
			}
		}
	}
}

// The width where the image bumps up against the edge of the container
@media(max-width: 428px) {
	.puppy {
		width: 100%;

		&__picture {
			width: 100%;
			object-fit: cover;
		}
	}

	.age {
		font-size: 1.75rem;
	}
}